import { nanoid } from 'nanoid'

// HEAD DATA
export const headData = {
  title: 'Teoian Dev', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: '' // e.g: Welcome to my website
}

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: '{Teoian}',
  subtitle: '',
  cta: ''
}

// ABOUT DATA
export const aboutData = {
  img: 'rounded-bw-profile.png',
  paragraphOne:
    "Hello, I'm a software developer passionate about technology and innovation, graduated in Computer Science at UFPA. I have 5 years of experience in software development and am always looking for new challenges to expand my knowledge and skills.",
  paragraphTwo:
    "With extensive experience in various technologies, such as Typescript, MySQL, NestJS, AWS, and skills in TDD, DDD, Clean Arch, Clean Code, DRY, KISS and OOP, I am very interested in specializing in Software Engineering. Currently, I'm working in a high-performance SaaS development team, putting all my knowledge and skills into practice.",
  paragraphThree:
    'My big goal is to be part of something that makes a significant impact on the world, and I believe that technology is a powerful tool to achieve that goal. If you are looking for a dedicated professional, committed to excellence and always looking for new challenges, please contact me. It will be a pleasure to share my experience and work on projects that make a difference.',
  resume: '' // if no resume, the button will not show up
}

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'experimentador-bw.png',
    title: 'Experimentador project',
    info: "Free sample submission platform. Made at Adventures.inc for Paco Rabanne's Christmas campaign in Brazil.",
    info2: 'We develop a scalable, resilient and fast system.',
    url: 'https://www.experimentador.com.br/',
    repo: '' // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'e-gestor-bw.png',
    title: 'E-Gestor project',
    info: 'Final version of the e-Gestor APP - Collaborative system for managing works and contracts. Now integrated with e-Fiscal, an inspection APP using georeferencing. (reporting/push service).',
    info2: '',
    url: 'https://www.linkedin.com/feed/update/urn:li:activity:6790944808618868736/',
    repo: '' // if no repo, the button will not show up
  }
]

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  whatsapp: '5511989252622'
}

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/quaresmateo'
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/teoian-quaresma/'
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/quaresmateo'
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://instagram.com/teoian.dev'
    }
  ]
}

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true // set to false to disable the GitHub stars/fork buttons
}
